.App {
    /*background-color: #EEE1FC;*/
    /*background-color: rgba(238, 225, 252, 1)*/
}

.radio-container {
    display: flex;
    align-items: center;
}

.radio-button {
    margin-left: auto; /* Push the radio button to the right */
}


.text-center {
    text-align: center;
}

.right-align {
    text-align: right;
}

.left-align {
    text-align: left;
}

.img-center {
    horiz-align: center;
}

.lg-view {
    display: inline-block;
}

.sm-view {
    display: none;
}

.sticky-header {
    position: sticky;
    top: 0;
}

.full-width {
    width: 100%;
}

.full-width-centered {
    display: flex;
    flex-direction: column;
    margin: auto; /* Center the form horizontally */
}

.classes-table {
    display: flex;
    flex-direction: column;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: auto; /* Center the form horizontally */
}

.even-row {
    background-color: #FFF2E9; /*#F6FFFF; /* Light Blue*/
    padding: 0;
}

.odd-row {
    background-color: #FFFEE0; /* Light yellow */
    padding: 0;
}

@media screen and (max-width: 500px) {
    .lg-view {
        display: none;
    }

    .sm-view {
        display: inline-block;
    }
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    /*background-color: rgba(238, 225, 252, 1)*/
}

form {
    display: flex;
    flex-direction: column;
    max-width: 300px; /* Adjust the maximum width as needed */
    margin: auto; /* Center the form horizontally */
}

label {
    margin-bottom: 8px;
}

p, ul, button {
    font-size: 1.25rem;
}

table {
    border-collapse: collapse;
}

td {
    padding: 0;
}

input,
textarea {
    padding: 8px;
    margin-bottom: 12px;
    /*width: 100%;*/
    box-sizing: border-box;
}

p:empty::before {
    content: "";
    display: inline-block;
}


/* CookiePopup.css */
.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f0f0f0;
    padding: 10px;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.cookie-popup button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
